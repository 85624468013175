<template>
    <div
        v-if="writers.length"
        class="order-detail__body_content_row"
    >
        <div class="order-detail__body_content_row_title">
            <component :is="icon" />
            {{ title }}
        </div>
        <div class="order-detail__body_content_row_data">
            <span
                v-for="(writer, index) in writers.slice(0, 5)"
                :key="index"
                class="writer-wrapper"
            >
                <span
                    class="writer-preferred report"
                >
                    <span class="tool">
                        <div class="writer-additional-output__writer-info">
                            <div><b>{{ writer.name }}</b> {{ writer.sw_id }}</div>
                            <div class="writer-additional-output__writer-info-records">
                                <span style="white-space: nowrap;">
                                    <b>{{ writer.statistics.orders_total | number }}</b> projects
                                </span>
                                <span
                                    v-if="writer.statistics.rank_all_count > 0"
                                    style="white-space: nowrap;"
                                >
                                    <b>{{ writer.statistics.rank_all | percentWriterRank }}</b> success
                                    based on <b>{{ writer.statistics.rank_all_count }}</b> reviews
                                </span>
                                <span
                                    v-else
                                    style="white-space: nowrap;"
                                >
                                    No reviews
                                </span>
                            </div>
                        </div>
                    </span>
                    <a
                        :href="'/writers/profile/' + writer.sw_id"
                        class="writer-link"
                    >
                        <avatar
                            :avatar-fallback="writer.avatar_fallback"
                            :avatar-url="writer.avatar_url"
                            :name="writer.name"
                            :size="25"
                        />
                    </a>
                </span>
            </span>
            <PreferredWritersAdditionWindow
                :writers="writers"
            />
        </div>
    </div>
</template>

<script>

import Writers from '@/components/icons/Writers'
import WritersBlocked from '@/components/icons/Writers-blocked'
import Avatar from '@/components/common/Avatar.vue'
import PreferredWritersAdditionWindow from '@/components/writers/PreferredWritersAdditionWindow';
import filtersMixin from '@/mixins/filters-mixin'

export default {
    name: 'PreferredWriters',
    components: {
        Preferred: Writers,
        Blocked: WritersBlocked,
        Avatar,
        PreferredWritersAdditionWindow
    },
    mixins: [
        filtersMixin
    ],
    props: {
        writers: {
            type: Array,
            required: true
        },
        title: {
            type: String
        },
        icon: {
            type: String
        }
    }
}
</script>
